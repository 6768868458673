<template>
  <pibot-mapbox-general-popup :lngLat="lngLat"
                              :bbox="bbox"
                              :addIncident="addIncidentBoolean"
                              :router="router">
    <template #title>
      <slot name="title">
          {{ computedTitle }}
      </slot>
    </template>

    <template #default
              v-if="feature.properties">
      <v-simple-table dense
                      class="pb-2">
        <tbody>
          <tr v-for="item in displayItems"
              :key="item.property">
            <td><small>{{ item.property }}</small></td>
            <td><small>{{ item.value | formatNumber }}</small></td>
            <td><small> {{ item.unit }}</small></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </pibot-mapbox-general-popup>
</template>

<script>
import _ from 'lodash'
import tooltipMetaMixin from '@/utils/mixins/meta/tooltipMeta.mixin'
import unitMetaMixin from '@/utils/mixins/meta/unitMeta.mixin'
import GeneralPopup from './GeneralPopup'
import config from '@/apps/mapbox/config'

export default {
  name: 'pibot-mapbox-datatable-popup',
  mixins: [tooltipMetaMixin, unitMetaMixin],
  components: {
    'pibot-mapbox-general-popup': GeneralPopup
  },
  props: {
    feature: {
      type: Object,
      required: true
    },
    hideProperties: {
      type: Array,
      default: () => ['charpy v-notch impact energy', 'concrete coating strength', 'end kp', 'start kp', 'maximum operating pressure', 'minimum operating pressure',
        'Name', 'Diameter', 'Product', 'shear fracture area', 'tensile strength', "young's modulus", 'yield strength']
    },
    lngLat: {
      type: Array
    },
    bbox: {
      type: Array
    },
    showProperties: {
      type: Array
    }
  },
  data () {
    return {
      appFeatures: config.features
    }
  },
  mounted () {
    if (this.showProperties && this.hideProperties) {
      console.warn('Using both showProperties and hideProperties is not supported. Ignoring hideProperties.')
    }
  },
  computed: {
    router () {
      return this.$router
    },
    addIncidentBoolean () {
      if (!this.appFeatures.incidentToggle) return false
      if (!Object.keys(this.feature.properties).includes('Name')) return false // Name is the unique identifier for pipeline features?
      return true
    },
    computedTitle () {
      let metaTitle
      if (this.layerMeta[this.feature.layer.id]) metaTitle = this.layerMeta[this.feature.layer.id].title
      else if (this.feature?.properties) {
        if (this.feature.properties['Event Type']) metaTitle = this.feature.properties['Event Type']
        else if (this.feature.properties['Feature Type']) metaTitle = this.feature.properties['Feature Type']
        else if (this.feature.properties.incident_name) metaTitle = this.feature.properties.incident_name
        else if (this.feature.properties.Code) metaTitle = this.feature.properties.Code
        else if (this.feature.properties.Feature) metaTitle = this.feature.properties.Feature
        else if (this.feature.properties.Name) metaTitle = this.feature.properties.Name
        else if (this.feature.properties['Pipeline Name']) metaTitle = this.feature.properties['Pipeline Name']
        else if (this.feature.properties.OPERATOR) metaTitle = this.feature.properties.OPERATOR
        if (metaTitle && Object.keys(this.feature.properties).includes('start kp') && Object.keys(this.feature.properties).includes('end kp')) {
          metaTitle += ': ' + (this.feature.properties['start kp'] / 1000).toString() + ' - ' + (this.feature.properties['end kp'] / 1000).toString() + ' km'
        }
      }
      return metaTitle || 'Info'
    },
    displayItems () {
      if (!this.feature.properties) return []
      const metaKeys = Object.keys(this.unitMeta)
      const items = _.keys(this.feature.properties)
        .filter(property => {
          if (this.showProperties) return this.showProperties.includes(property)
          else if (this.hideProperties) return !this.hideProperties.includes(property)
          /**
           * Hide properties which don't have a value.
           * Also filter for String 'null' because queryRenderedFeatures() converts null to 'null'
           */
          else if (this.feature.properties[property] && this.feature.properties[property] !== 'null') return property
          else return false
        })
        .map(property => {
          return {
            property: metaKeys.includes(property) ? this.unitMeta[property].label : property,
            value: metaKeys.includes(property) ? this.feature.properties[property] * this.unitMeta[property].operation : this.feature.properties[property],
            unit: metaKeys.includes(property) ? this.unitMeta[property]['presenting unit'] : ''
          }
        })
      return _.sortBy(items, ['property'])
    }
  },
  filters: {
    formatNumber (value) {
      if (isNaN(value)) return value // Not a number
      if (Math.floor(value) === Number(value)) return value // No decimals

      const numDec = value.toString().split('.')[1].length || 0 // Count number of decimals

      if (numDec > 3) return Number(value).toFixed(3) // Format to 3 decimals if there are more than 3
      return value // Just return the original value
    }
  }
}
</script>

<style>
.mapboxgl-popup {
  max-width: 25rem !important;
}

.mapboxgl-popup-content {
  padding: 0px;
}
</style>
