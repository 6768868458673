var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pibot-mapbox-general-popup',{attrs:{"lngLat":_vm.lngLat,"bbox":_vm.bbox},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title",function(){return [_vm._v(" Cathodic Protection Measurement ")]})]},proxy:true},(_vm.properties)?{key:"default",fn:function(){return [_c('v-card-text',[_c('v-simple-table',{staticClass:"py-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.infoItems),function(ref,i){
var key = ref.key;
var value = ref.value;
return [(key && value)?_c('tr',{key:("cp-info-item-" + i)},[_c('td',[_c('strong',[_c('small',{domProps:{"innerHTML":_vm._s(key)}})])]),_c('td',[_c('small',{domProps:{"textContent":_vm._s(value)}})])]):_vm._e()]})],2)]},proxy:true}],null,false,1026949926)}),_c('v-simple-table',{staticClass:"py-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.dataHeaders),function(header,i){return _c('th',{key:("cp-data-header-" + i),staticClass:"text-left",domProps:{"textContent":_vm._s(header)}})}),0)]),_c('tbody',_vm._l((_vm.dataItems),function(ref,i){
var date = ref.date;
var voff = ref.voff;
var von = ref.von;
return _c('tr',{key:("cp-data-item-" + i)},[_c('td',[_c('small',{domProps:{"textContent":_vm._s(date)}})]),_c('td',[_c('small',{domProps:{"textContent":_vm._s(voff)}})]),_c('td',[_c('small',{domProps:{"textContent":_vm._s(von)}})])])}),0)]},proxy:true}],null,false,2128153957)})],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }