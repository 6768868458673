import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import _ from 'lodash'

import DataTablePopup from '@/components/mapbox/popups/DataTablePopup'
import iliPopup from '@/components/mapbox/popups/iliPopup'
import bathyPopup from '@/components/mapbox/popups/bathyPopup'
import cpPopup from '@/components/mapbox/popups/cpPopup'
import incidentPopup from '@/components/mapbox/popups/incidentPopup'
import Tooltip from '@/components/mapbox/popups/Tooltip'

import mapboxSpiderifyMixin from '@/utils/mixins/mapbox/mapboxSpiderify.mixin'

export default {
  mixins: [mapboxSpiderifyMixin],
  data () {
    return {
      activeTooltip: null
    }
  },
  computed: {
    ...mapState({
      map: state => state.mapbox.map,
      layerObjs: state => state.mapbox.layerObjs,
      sourceObjs: state => state.mapbox.sourceObjs,
      isMeasuring: state => state.mapbox.isMeasuring
    })
  },
  methods: {
    ...mapMutations({
      setActiveMouseLine: 'mapbox/setActiveMouseLine',
      setMouseKp: 'mapbox/setMouseKp'
    }),
    addPopup (feature, lngLat, layerId, PopupComponent, popupProps, offset = undefined) {
      const popupLngLat = this.popupLatLng(feature, lngLat)

      const popup = new mapboxgl.Popup({
        closeButton: false,
        maxWidth: 'none',
        offset,
        className: PopupComponent.name.includes('tooltip') ? 'tooltip' : ''
      }).setLngLat(popupLngLat)
        .setHTML('<div id="vue-popup-content"></div>')
        .addTo(this.map)

      const PopupConstructor = Vue.extend(PopupComponent)
      new PopupConstructor({ // eslint-disable-line no-new
        el: '#vue-popup-content',
        propsData: popupProps,
        router: this.$router
      })

      /**
       * Remove and re-add the popup to force it to be re-rendered.
       * This is necessary to ensure correct anchoring of the popup.
       */
      popup.remove().addTo(this.map)
      return popup
    },
    addSpider (feature, layerId) {
      this.map.getSource(layerId).getClusterLeaves(feature.properties.cluster_id, Infinity, 0, (err, features) => {
        if (err) throw err
        this.spiderifier.spiderfy(feature.geometry.coordinates, features.map(f => {
          this.setSpiderActiveState(true)
          // Add the layer ID to the spiderified feature
          f.properties.layerId = layerId
          return f
        }))
      })
      return null
    },
    attachPopupListeners () {
      this.map.on('mousemove', event => {
        if (this.activeTooltip) this.activeTooltip.remove()
        if (this.isMeasuring) return

        const { feature, snappedPoint } = this.closestFeatureFromMouseEvent(
          event,
          _.map(_.filter(this.layerObjs, 'properties.isPopup'), 'id') // IDs of all layers with 'isPopup: true'
        )

        if (feature) {
          const { 'Pipeline Name': pipelineName, Name, Pipeline } = feature.properties
          let pipeline = pipelineName || Name || Pipeline
          // EPRS demo exception
          if (!pipeline && feature.source === 'demo-asset-3') pipeline = 'Demo asset 3'
          if (pipeline) {
            // Used in BottomChart.Vue
            this.setActiveMouseLine(pipeline)

            const pipes = this.sourceObjs.filter(s => {
              return s.data && s.type !== 'raster' && (s.data.name === pipeline || pipeline.toLowerCase().includes(s.properties.id))
            })
            let pipeCoors
            if (pipes[0] && pipes[0].data.features.length > 1) {
              pipeCoors = []
              const nFeatures = pipes[0].data.features.length
              for (let i = 0; i < nFeatures; i++) {
                const feature = pipes[0].data.features[i]
                const nCoors = feature.geometry.coordinates.length
                for (let j = 0; j < nCoors - 1; j++) {
                  pipeCoors.push(feature.geometry.coordinates[j])
                }
                if (i === nFeatures - 1) {
                  pipeCoors.push(feature.geometry.coordinates[nCoors - 1])
                }
              }
            } else if (pipes[0] && pipes[0].data.features.length === 1) {
              pipeCoors = pipes[0].data.features[0].geometry.coordinates
            } else {
              pipeCoors = null
            }

            if (!pipeCoors) return

            const pipeLine = turf.lineString(pipeCoors)
            const start = turf.point(pipeCoors[0])
            const segment = turf.lineSlice(start, snappedPoint, pipeLine)
            this.setMouseKp(turf.length(segment, { units: 'meters' }))
          }
          this.map.getCanvas().style.cursor = 'pointer'
        } else {
          this.setActiveMouseLine(undefined)
          this.map.getCanvas().style.cursor = ''
          return // No feature clicked
        }

        if (!this.spiderActive) {
          this.activeTooltip = this.addPopup(feature, turf.getCoord(snappedPoint), feature.layer.id, Tooltip, {
            feature: feature,
            lngLat: event.lngLat
          })
        }
      })

      this.map.on('click', (event) => {
        if (this.activeTooltip) this.activeTooltip.remove()
        if (this.isMeasuring) return

        const { feature, snappedPoint } = this.closestFeatureFromMouseEvent(
          event,
          _.map(_.filter(this.layerObjs, 'properties.isPopup'), 'id') // IDs of all layers with 'isPopup: true'
        )

        if (feature) {
          this.map.getCanvas().style.cursor = 'pointer'
        } else {
          this.map.getCanvas().style.cursor = ''
          return // No feature clicked
        }

        if (feature.properties.cluster && !this.eventFiredBySpider(event)) { // spinnetje
          this.addSpider(feature, feature.layer.id)
        } else if (!this.eventFiredBySpider(event)) { // popup
          // get full geometry from full feature data for pipelines
          let bbox
          const fC = this.sourceObjs.find(s => s.properties.id === feature.layer.source).data
          if (fC.features.length === 1) bbox = turf.bbox(fC)
          else bbox = undefined

          let PopupCompToMount = DataTablePopup
          // Check if the popup should be created for an ILI feature
          if (feature.properties.Event_type) PopupCompToMount = bathyPopup
          else if (feature.properties['Inspection Type']) PopupCompToMount = iliPopup
          else if (feature.layer.id.includes('cp-points')) PopupCompToMount = cpPopup
          else if (feature.layer.id.includes('eprs-incidents')) PopupCompToMount = incidentPopup

          this.addPopup(feature, turf.getCoord(snappedPoint), feature.layer.id, PopupCompToMount, {
            feature,
            lngLat: turf.getCoord(snappedPoint),
            bbox
            // showProperties: ['Length', 'Product', 'Service', 'Code', 'Easting_event', 'Northing_event', 'c_meter', 'Pipeline', 'Remarks', 'Year', 'Event_m'] // TODO get this from the database
          })
        }
      })
    },
    closestPoint (feature, lngLat) {
      const featureType = turf.getType(feature)

      let point
      if (Array.isArray(lngLat)) {
        point = turf.point(lngLat)
      } else if (lngLat.lat && lngLat.lng) {
        point = turf.point(lngLat.toArray())
      } else if (turf.getType(lngLat) === 'Point') {
        point = lngLat
      }

      if (featureType === 'Point') { // Point
        return feature
      } else if (featureType.endsWith('LineString')) { // (Multi)LineString
        return turf.nearestPointOnLine(feature, point, {})
      } else if (featureType === 'MultiPoint') { // MultiPoint
        const pointCollection = turf.flatten(feature)
        return turf.nearestPoint(point, pointCollection)
      } else if (featureType === 'Polygon') { // Polygon
        const convertedLine = turf.polygonToLine(feature)
        return turf.nearestPointOnLine(convertedLine, point)
      } else if (featureType === 'MultiPolygon') { // MultiPolygon
        // const polygonCollection = turf.flatten(feature)
        // TODO implement?; maybe mapbox only returns Polygons
        throw Error('Not implemented for type MultiPolygon')
      } else {
        throw Error(`Unknown Geometry type: ${featureType}`)
      }
    },
    popupLatLng (clickedFeature, mouseLngLat) {
      const closestPoint = this.closestPoint(clickedFeature, mouseLngLat)
      return turf.getCoord(closestPoint)
    }
  }
}
