import MapboxglSpiderifier from 'mapboxgl-spiderifier'

import Tooltip from '@/components/mapbox/popups/Tooltip'
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      spiderifier: state => state.mapbox.spiderifier,
      spiderActive: state => state.mapbox.spiderActive
    })
  },
  methods: {
    ...mapMutations({
      setSpiderifier: 'mapbox/setSpiderifier',
      unspiderify: 'mapbox/unspiderify',
      setSpiderActiveState: 'mapbox/setSpiderActiveState'
    }),
    initSpiderify () {
      this.setSpiderifier(new MapboxglSpiderifier(this.map, {
        initializeLeg: spiderLeg => {
          const pinStyle = spiderLeg.elements.pin.style
          const circleColorProperty = this.map.getPaintProperty(spiderLeg.feature.properties.layerId, 'circle-color')
          const circleStrokeColorProperty = this.map.getPaintProperty(spiderLeg.feature.properties.layerId, 'circle-stroke-color')

          const pinRadius = 20

          pinStyle.width = `${pinRadius}px`
          pinStyle.height = `${pinRadius}px`
          pinStyle.borderRadius = '50%'
          pinStyle.marginLeft = `-${pinRadius / 2}px`
          pinStyle.marginTop = `-${pinRadius / 2}px`
          pinStyle.borderWidth = '1px'
          pinStyle.borderStyle = 'solid'
          pinStyle.backgroundColor = this.resolveExpression(circleColorProperty, spiderLeg.feature) === null ? '#000' : this.resolveExpression(circleColorProperty, spiderLeg.feature)
          pinStyle.borderColor = this.resolveExpression(circleStrokeColorProperty, spiderLeg.feature)

          const hoverElement = spiderLeg.elements.pin

          hoverElement.addEventListener('mouseenter', event => {
            if (this.activeTooltip) this.activeTooltip.remove()

            const feature = spiderLeg.feature
            const popup = this.addPopup(feature, spiderLeg.feature.geometry.coordinates, '', Tooltip, {
              feature,
              lngLat: event.lngLat
            }, MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg))

            spiderLeg.mapboxMarker.setPopup(popup)

            hoverElement.addEventListener('mouseleave', () => {
              popup.remove()
            })
          })
        },
        animate: true,
        onClick: (_e, marker) => {
          this.panToCoords({ center: marker.feature.geometry.coordinates })
        }
      }))

      this.map.on('zoomstart', () => {
        this.unspiderify()
      })
      this.map.on('click', () => {
        this.unspiderify()
      })
    },
    pinHover () {
      if (!document.querySelector('.spider-leg-pin')) return false
      const pins = document.querySelectorAll('.spider-leg-pin')
      for (const pin of pins) {
        if (pin.parentElement.querySelector(':hover')) return true
      }
      return false
    },
    eventFiredBySpider (e) {
      return e.originalEvent.target.className.includes('spider-leg-pin')
    }
  }
}
