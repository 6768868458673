<template>
  <v-card class="mx-auto">
    <v-card-title class="pa-0 mb-3">
      <v-toolbar dense
                 dark
                 flat
                 tile
                 color="primary darken-1">
        <v-row no-gutters
               align="end"
               justify="space-around"
               style="max-width: 100%;">
          <v-col class="text-truncate text-subtitle-1 font-weight-bold"
                 :title="titleAttr">
            <slot name="title" />
          </v-col>

          <v-btn dark
                 small
                 icon
                 plain
                 color="white"
                 class="ma-0 ml-3"
                 v-if="bbox || lngLat"
                 @click="panToFeature(bbox, lngLat)">
            <!-- Easy-fix for v-icon mounting error -->
            <i v-if="bbox" aria-hidden="true" class="v-icon notranslate material-icons theme--dark">zoom_out_map</i>
            <i v-else-if="lngLat" aria-hidden="true" class="v-icon notranslate material-icons theme--dark">zoom_in</i>
            <!-- <v-icon v-if="bbox"
                    dark>zoom_out_map</v-icon>
            <v-icon v-else-if="lngLat"
                    dark>zoom_in</v-icon> -->
          </v-btn>
          <!-- Add incident button -->
          <v-tooltip top v-if="addIncident">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                    dark
                    small
                    icon
                    color="white"
                    class="ma-0 ml-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="addIncidentClick()"
                    >
                <i aria-hidden="true" class="v-icon notranslate material-icons theme--dark">add</i>
              </v-btn>
            </template>
            <span>Add incident</span>
          </v-tooltip>
          <!-- Detailed incident button -->
          <v-tooltip top v-if="incident">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                    dark
                    small
                    icon
                    color="white"
                    class="ma-0 ml-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="incidentInfoClick"
                    >
                <i aria-hidden="true" class="v-icon notranslate material-icons theme--dark">info</i>
              </v-btn>
            </template>
            <span>Detailed incident page</span>
          </v-tooltip>
        </v-row>
      </v-toolbar>
    </v-card-title>

    <slot></slot>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'pibot-mapbox-general-popup',
  props: {
    lngLat: {
      type: Array
    },
    bbox: {
      type: Array
    },
    addIncident: {
      type: Boolean,
      default: false
    },
    incident: {
      type: Boolean,
      default: false
    },
    incident_id: {
      type: String
    }
  },
  computed: {
    ...mapState({
      mouseKp: state => state.mapbox.mouseKp
    }),
    titleAttr () {
      return this.$slots.title[0].text
    }
  },
  methods: {
    ...mapActions({
      panToCoords: 'mapbox/mapPanToCoords',
      panToBbox: 'mapbox/mapPanToBbox'
    }),
    ...mapMutations({
      setKpClick: 'eprs/SET_KP_CLICK'
    }),
    panToFeature (bbox, lngLat) {
      if (!bbox) this.panToCoords({ center: lngLat })
      else this.panToBbox({ bbox: [[bbox[0], bbox[1]], [bbox[2], bbox[3]]] })
    },
    addIncidentClick () {
      // get clicked kp and save in store
      this.setKpClick(Math.round(100 * this.mouseKp) / 100)
      this.$router.push('eprs/incident/new')
    },
    incidentInfoClick () {
      if (this.incident_id) this.$router.push({ name: 'eprs-assessment', params: { iid: this.incident_id } })
    }
  }
}
</script>

<style>
.mapboxgl-popup-anchor-top:not(.tooltip) .mapboxgl-popup-tip {
  border-bottom-color: var(--v-primary-darken1) !important;
}
</style>
