<template>
  <mapbox
    class="pibot-map"
    ref="map"
    :access-token="mapboxToken"
    :map-options="{...mapOptions, interactive}"
    :scale-control="mapScaleControl"
    :nav-control="mapNavControl"
    :style="styling"
    @map-init="mapInitialized"
    @map-load="mapLoaded"
  />
</template>

<script>
import Mapbox from 'mapbox-gl-vue'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxDataMixin from '@/utils/mixins/mapbox/mapboxData.mixin.js'
import mapboxPopupMixin from '@/utils/mixins/mapbox/mapboxPopup.mixin'
import mapboxLegendsMixin from '@/utils/mixins/mapbox/mapboxLegends.mixin'
import { mapState, mapMutations, mapGetters } from 'vuex'
import fireStationIcon from '@/assets/fire-station-15.png'

export default {
  name: 'pibot-mapbox',
  mixins: [mapboxBasicsMixin, mapboxDataMixin, mapboxPopupMixin, mapboxLegendsMixin],
  components: { Mapbox },
  props: {
    interactive: {
      type: Boolean,
      default: true
    },
    styling: {
      type: Object
    },
    zoomTo: {
      type: String
    },
    panTo: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      baseMapStyles: state => state.mapbox.mapStyles,
      baseMapStyle: state => state.mapbox.mapStyle,
      mapboxToken: state => state.mapbox.mapboxToken,
      mapScaleControl: state => state.mapbox.mapScaleControl,
      mapFullScreenControl: state => state.mapbox.mapFullScreenControl,
      mapNavControl: state => state.mapbox.mapNavControl,
      mapLoadedState: state => state.mapbox.mapLoaded
    }),
    ...mapGetters({
      mapOptions: 'mapbox/mapOptions'
    })
  },
  methods: {
    ...mapMutations({
      setMapboxInstance: 'mapbox/setMapboxInstance',
      setLoadedState: 'mapbox/setLoadedState',
      setIdleState: 'mapbox/setIdleState',
      setMouseCoords: 'mapbox/setMouseCoords'
    }),
    mapInitialized (map) {
      this.setMapboxInstance(map)
      this.initSpiderify()
    },
    mapLoaded (map) {
      this.setLoadedState(true)
      this.initData()
        .then(() => {
          if (this.$route.query.zoomto || this.zoomTo) this.zoomToLayer(this.$route.query.zoomto || this.zoomTo)
          else if (this.panTo) {
            this.zoomToLayer(this.panTo.layerId) // Hacky way to perform required preparations
            this.panToCoords({ center: this.panTo.coordinates, zoom: this.panTo.zoom, jump: true })
          } else this.moveActiveLayersInView()
        })
      this.map.loadImage(fireStationIcon, function (error, image) {
        if (error) throw error
        map.addImage('incident-icon', image, { sdf: true })
      })
      this.map.on('style.load', this.reloadLayers)

      if (!this.interactive) return

      this.attachPopupListeners()
      this.attachRenderListener()
      this.attachIdleListener()
      this.attachHoverStateListener()
      this.attachMouseCoordsListener()
    },
    attachRenderListener () {
      this.map.on('styledata', this.mapRender)
    },
    mapRender () {
      this.setIdleState(false)
    },
    attachIdleListener () {
      this.map.on('idle', this.mapIdle)
    },
    mapIdle () {
      this.setIdleState(true)
    },
    attachMouseCoordsListener () {
      this.map.on('mousemove', e => {
        this.setMouseCoords(e.lngLat.wrap())
      })
    }
  },
  watch: {
    $route (to, from) {
      if (to.path !== '/mapviewer') return
      if (!to.query.zoomto) return
      this.zoomToLayer(to.query.zoomto)
    }
  }
}
</script>

<style scoped>
@import 'spyderify.scss';

.pibot-map {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
</style>
