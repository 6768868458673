import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import { mapState, mapMutations } from 'vuex'

export default {
  mixins: [mapboxBasicsMixin],
  computed: {
    ...mapState({
      legends: state => state.legends.allLegends
    })
  },
  methods: {
    ...mapMutations({
      setActiveLegends: 'legends/setActiveLegends'
    }),
    processLegends () {
      const activeLegends = []
      const pushedLegends = []

      for (const key in this.legends) {
        const legend = this.legends[key]
        if (!legend.applicableLayers || pushedLegends.includes(legend._id)) continue

        const willBeActive = this.activeLayers.some(x => legend.applicableLayers.includes(x))
        if (willBeActive) {
          activeLegends.push(legend)
          pushedLegends.push(legend._id)
        }
      }

      this.setActiveLegends(activeLegends)
    }
  },
  watch: {
    activeLayers (newValue) {
      // Set active legends according to newValue
      this.processLegends()
    }
  }
}
