<template>
  <div class="pa-2">
    <span style="white-space: pre;" v-text="computedTitle" />
  </div>
</template>

<script>
import _ from 'lodash'
import tooltipMetaMixin from '@/utils/mixins/meta/tooltipMeta.mixin'

export default {
  name: 'pibot-mapbox-tooltip',
  mixins: [tooltipMetaMixin],
  props: {
    feature: {
      type: Object,
      required: true
    },
    lngLat: {
      type: Object
    },
    lngLatDecimals: {
      type: Number,
      default: 6,
      validator: value => value >= 0
    }
  },
  data () {
    return {
      defaultTitle: 'Info'
    }
  },
  computed: {
    computedTitle () {
      if (!(this.feature && this.feature.properties)) return this.defaultTitle

      /* eslint-disable camelcase */
      const {
        cluster,
        point_count,
        'Pipeline Name': PipelineName,
        Name,
        OPERATOR,
        'Event Type': EventType,
        Code,
        Feature,
        'Feature Type': featureType,
        incident_name: incident,
        Intensity: intensity,
        layerId,
        scenario
      } = this.feature.properties
      if (cluster) return `${point_count} clustered items`

      let meta
      // EPRS demo exception
      if (this.feature.source === 'demo-asset-3') meta = 'Demo asset 3'
      else if (this.feature?.layer?.id && this.layerMeta[this.feature.layer.id]) meta = this.layerMeta[this.feature.layer.id]
      else if (layerId && this.layerMeta[layerId]) meta = this.layerMeta[layerId]

      const metaTitle = meta?.dynamic ? `${meta?.title}:\n${this.feature.properties[meta?.dynamic]}` : meta?.title

      return (
        metaTitle ||
        incident ||
        intensity ||
        EventType ||
        featureType ||
        Code ||
        Feature ||
        Name ||
        PipelineName ||
        OPERATOR ||
        scenario ||
        this.defaultTitle
      )
    },
    displayLng () {
      return _.round(this.lngLat.lng, this.lngLatDecimals)
    },
    displayLat () {
      return _.round(this.lngLat.lat, this.lngLatDecimals)
    }
  }
}
</script>

<style>

</style>
