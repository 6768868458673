export default {
  data () {
    return {
      layerMeta: {
        fishing: {
          title: 'Fishing Intensity',
          dynamic: 'Intensity'
        },
        'road-crossings': {
          title: 'Road Crossing',
          description: ''
        },
        'river-crossings': {
          title: 'River Crossing',
          description: ''
        },
        'railway-crossings': {
          title: 'Railway Crossing',
          description: ''
        },
        'u06b-onshore': {
          title: 'U06c IJmuiden Valve Station to Oiltanking Amsterdam',
          description: ''
        },
        'u06c-qra-points': {
          title: 'U06c QRA Point',
          description: ''
        },
        'cp-markers': {
          title: 'Cathodic Protection Marker',
          description: ''
        },
        'marker-poles': {
          title: 'Marker Pole',
          description: ''
        },
        'settlement-beacons': {
          title: 'Settlement Beacon',
          description: ''
        },
        'sinker-markers': {
          title: 'Sinker Marker',
          description: ''
        },
        'dcvg-2013': {
          title: 'DCVG 2013',
          description: ''
        },
        'dcvg-2018': {
          title: 'DCVG 2018',
          description: ''
        },
        'acvg-2018': {
          title: 'ACVG 2018',
          description: ''
        },
        'acvg-2019': {
          title: 'ACVG 2019',
          description: ''
        },
        'acvg-2020': {
          title: 'ACVG 2020',
          description: ''
        },
        'cp-2015': {
          title: 'Cathodic Protection status 2015',
          description: ''
        },
        'cp-2017': {
          title: 'Cathodic Protection status 2017',
          description: ''
        },
        'cable-telecom': {
          title: 'Cable: Telecom',
          description: ''
        },
        'cable-coaxiaal': {
          title: 'Cable: Coaxiaal',
          description: ''
        },
        'cable-electra': {
          title: 'Cable: Electra',
          description: ''
        },
        'cable-umbilical': {
          title: 'Cable: Umbilical',
          description: ''
        }
      }
    }
  }
}
