import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  mixins: [mapboxBasicsMixin],
  computed: {
    ...mapState({
      map: state => state.mapbox.map,
      layersPending: state => state.mapbox.layersPending,
      legends: state => state.legends.allLegends,
      unifiedTurfFilters: state => state.mapbox.unifiedTurfFilters
    }),
    ...mapGetters({
      sourceObjs: 'mapbox/sourceObjs',
      layerObjs: 'mapbox/layerObjs',
      activeLayers: 'mapbox/activeLayers',
      sourceIdList: 'mapbox/sourceIds'
    })
  },
  methods: {
    ...mapMutations({
      setSourceObjState: 'mapbox/updateSourceObj',
      setDataPendingState: 'mapbox/setDataPendingState',
      setLayersPendingState: 'mapbox/setLayersPendingState',
      setActiveLayers: 'mapbox/setActiveLayers'
    }),
    ...mapActions({
      fetchSources: 'mapbox/socket_fetchSources',
      fetchLayers: 'mapbox/socket_fetchLayers',
      fetchSourceData: 'mapbox/socket_fetchSourceData',
      fetchLegends: 'legends/socket_fetchLegends',
      setAndApplyTurfFilters: 'mapbox/setAndApplyTurfFilters'
    }),
    initData () {
      return new Promise((resolve, reject) => {
        const promises = []

        if (!this.sourceObjs.length) promises.push(this.fetchSources())
        if (!this.layerObjs.length) promises.push(this.fetchLayers())
        if (!this.legends.length) promises.push(this.fetchLegends())

        const _afterFetch = () => {
          // add sources to mapbox
          this.processSources(true)
            .then(() => {
              // Add layers to mapbox
              this.processLayers(true)
              // Finished init
              this.setDataPendingState(false)
              resolve()
            })
        }

        if (!promises.length) {
          _afterFetch()
          return
        }

        this.setDataPendingState(true)

        Promise.all(promises)
          .then(() => {
            _afterFetch()
          })
          .catch(() => {
            // Notify user about error
          })
      })
    },
    processSources (isInit) {
      // Sources must be set before they can be used in layers, so we return a Promise to make sure it finished
      return new Promise((resolve, reject) => {
        if (!this.sourceObjs.length) {
          resolve()
          return
        }
        let i = 0

        // Function to add a source and it's possible data to Mapbox
        const _processSource = (source, data) => {
          i++
          const { id } = source.properties

          // Set data
          if (data) {
            // Assign data
            Object.assign(source, data)
            // Update source Object in state
            this.setSourceObjState(source)
          }

          /**
           * Remove the 'properties' property from the source Object.
           * This will leave a valid mapbox source Object which can then be added.
           * Please note that the 'properties' property of 'source' is not the same as 'properties' in a 'feature'.
           */
          delete source.properties
          delete source._id
          delete source.__v
          delete source.lastUpdate // Deprecated
          delete source.createdAt
          delete source.updatedAt
          this.map.addSource(id, source)

          // resolve when data is fetched for all sources
          if (i === this.sourceObjs.length) resolve()
        }

        // Add sources to Mapbox
        // eslint-disable-next-line
        for (let source of this.sourceObjs) {
          // Clone Object to prevent accidentally modifing the $store
          source = JSON.parse(JSON.stringify(source))

          // Deconstruct 'properties'
          const { id, isInitial, isMandatory } = source.properties

          if (this.map.getSource(id) !== undefined) continue // Source has already been set

          // Should we fetch the data of the current source?
          if (isInit && (isInitial || isMandatory) && !this.sourceHasData(source)) {
            // Fetch source data before adding the source to Mapbox
            this.fetchSourceData(source)
              .then((data) => {
                _processSource(source, data)
              })
          } else {
            // Add the source to Mapbox without data
            _processSource(source)
          }
        }
      })
    },
    processLayers (isInit) {
      this.setLayersPendingState(true)

      for (let layer of this.layerObjs) {
        // Clone Object to prevent accidentally modifing the $store
        layer = JSON.parse(JSON.stringify(layer))

        if (this.map.getLayer(layer.id) !== undefined) continue // Layer has already been set

        // Deconstruct 'properties'
        const { isInitial: layerIsInitial } = layer.properties

        // Set default 'before_id'
        const beforeId = layer.properties.beforeId ? layer.properties.beforeId : 'waterway-label'

        /**
         * Remove the 'properties' property from the layer Object.
         * This will leave a valid mapbox layer Object which can then be added.
         */
        delete layer.properties
        delete layer._id
        delete layer.__v
        delete layer.lastUpdate // Deprecated
        delete layer.createdAt
        delete layer.updatedAt
        this.map.addLayer(layer, beforeId)

        // Check if this layer has a valid source
        let layerSource
        if (!layer.source) layerSource = false
        else if (typeof layer.source === 'string') layerSource = this.map.getSource(layer.source)
        else layerSource = true

        // Run source check and set initial layer visibility
        if (layerSource) {
          // Check if the source is flagged as 'isInitial'
          const sourceHasData = this.sourceHasData(layerSource)

          // Set initial visibility of this layer
          if (isInit && (layerIsInitial && !sourceHasData)) console.warn(`layer '${layer.id}' is flagged as 'initial', but it's source is not. Skipping this layer as 'initial'.`)
          else if (isInit) this.setLayerVisibility(layer.id, layerIsInitial)
        }
      }

      // Loading layers fisnished
      this.setLayersPendingState(false)
    },
    /**
     * Function to reload all 'visible' layers.
     * Primarily used after changing base style.
     */
    reloadLayers () {
      this.processSources(false)
        .then(() => {
          this.processLayers(false)
          /**
           * In an earlier version, we only set the visibility of the layers in this.activeLayers.
           * Something changed in Mapbox which causes the need for looping over all layers and
           * setting their visibility according to their presence in this.activeLayers.
           */
          for (const layer of this.layerObjs) {
            this.setLayerVisibility(layer.id, this.activeLayers.includes(layer.id))
          }
          this.setAndApplyTurfFilters(this.unifiedTurfFilters)
        })
    },
    reloadSources (sourceIds) {
      // If no explicit source ID's set, fallback to all
      if (!Array.isArray(sourceIds) || !sourceIds.length) sourceIds = this.sourceIdList

      sourceIds.forEach(id => {
        this.map.getSource(id).setData(this.getSourceDataById(id))
      })
    },
    getSourceDataById (sourceId) {
      return this.sourceObjs.find(src => src.properties.id === sourceId).data
    },
    sourceHasData (source) {
      // Data dictionary
      let hasError = false
      let dataKeys
      switch (source.type) {
        case 'vector':
        case 'raster':
        case 'raster-dem':
          dataKeys = ['url', 'tiles']
          break

        case 'geojson':
          dataKeys = ['_data', 'data']
          break

        case 'image':
        case 'video':
          dataKeys = ['url']
          break

        default:
          hasError = true
          console.error(`invalid source type '${source.type}'`)
          break
      }

      if (hasError) return false

      // Check for valid data
      let found = false
      for (const dataKey of dataKeys) {
        if (!source[dataKey]) continue
        found = true
        break
      }
      return found
    },
    getLayerIdsByCategory (category) {
      if (!category || typeof category !== 'string') return []

      return this.layerObjs.filter(l => l.properties.category === category).map(l => l.id)
    },
    getLayerIdsByCategoryType (type) {
      if (!type || !type.length) return []

      let result = []
      if (Array.isArray(type)) result = this.layerObjs.filter(l => type.includes(l.properties.categoryType)).map(l => l.id)
      else result = this.layerObjs.filter(l => l.properties.categoryType === type).map(l => l.id)
      return result
    }
  }
}
