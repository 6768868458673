<template>
  <pibot-mapbox-datatable-popup v-bind="$options.propsData">
    <template #title>
      <p class="text-body-2 ma-0">
        {{properties['Event Type']}}
      </p>
      <p class="text-overline ma-0">
        {{properties['Pipeline']}} - {{properties['Year']}}
      </p>
    </template>
  </pibot-mapbox-datatable-popup>
</template>

<script>
import DataTablePopup from './DataTablePopup'

export default {
  name: 'pibot-mapbox-bathy-popup',
  inheritAttrs: false,
  components: {
    'pibot-mapbox-datatable-popup': DataTablePopup
  },
  computed: {
    properties () {
      return this.$options.propsData.feature.properties
    }
  }
}
</script>
