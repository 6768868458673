export default {
  data () {
    return {
      unitMeta: {
        Diameter: {
          'incoming unit': '',
          'presenting unit': 'inch',
          operation: 1,
          label: 'Outer diameter '
        },
        'charpy v-notch impact energy': {
          'incoming unit': 'J',
          'presenting unit': 'J',
          operation: 1,
          label: 'Charpy v-notch impact energy'
        },
        MAOP: {
          'incoming unit': 'barg',
          'presenting unit': 'barg',
          operation: 1,
          label: 'MAOP'
        },
        'concrete coating strength': {
          'incoming unit': 'Pa',
          'presenting unit': 'MPa',
          operation: 1 / 1000000,
          label: 'Concrete coating strength'
        },
        'concrete coating thickness': {
          'incoming unit': 'm',
          'presenting unit': 'mm',
          operation: 1000,
          label: 'Concrete coating thickness'
        },
        'design pressure': {
          'incoming unit': 'barg',
          'presenting unit': 'barg',
          operation: 1,
          label: 'Design pressure'
        },
        'end kp': {
          'incoming unit': 'm',
          'presenting unit': 'km',
          operation: 1 / 1000,
          label: 'Kp end'
        },
        'maximum operating pressure': {
          'incoming unit': 'barg',
          'presenting unit': 'barg',
          operation: 1,
          label: 'Maximum operating pressure'
        },
        'minimum operating pressure': {
          'incoming unit': 'barg',
          'presenting unit': 'barg',
          operation: 1,
          label: 'Minimum operating pressure'
        },
        'outer diameter': {
          'incoming unit': 'm',
          'presenting unit': 'mm',
          operation: 1000,
          label: 'Outer diameter'
        },
        'polymere coating thickness': {
          'incoming unit': 'm',
          'presenting unit': 'mm',
          operation: 1000,
          label: 'Polymere coating thickness'
        },
        'shear fracture area': {
          'incoming unit': 'm^2',
          'presenting unit': 'mm^2',
          operation: 1000000,
          label: 'Shear fracture area'
        },
        'start kp': {
          'incoming unit': 'm',
          'presenting unit': 'km',
          operation: 1 / 1000,
          label: 'Kp start'
        },
        'tensile strength': {
          'incoming unit': 'Pa',
          'presenting unit': 'MPa',
          operation: 1 / 1000000,
          label: 'Tensile strength'
        },
        'wall thickness': {
          'incoming unit': 'm',
          'presenting unit': 'mm',
          operation: 1000,
          label: 'Wall thickness'
        },
        'yield strength': {
          'incoming unit': 'Pa',
          'presenting unit': 'MPa',
          operation: 1 / 1000000,
          label: 'Yield strength'
        },
        "young's modulus": {
          'incoming unit': 'Pa',
          'presenting unit': 'MPa',
          operation: 1 / 1000000,
          label: "Young's modulus"
        },
        '3-layer polyethylene': {
          'incoming unit': 'm',
          'presenting unit': 'mm',
          operation: 1000,
          label: '3-Layer polyethylene'
        },
        Length: {
          'incoming unit': 'm',
          'presenting unit': 'km',
          operation: 1 / 1000,
          label: 'Total length'
        }
      }
    }
  }
}
