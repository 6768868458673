<template>
  <pibot-mapbox-general-popup :lngLat="lngLat"
                              :bbox="bbox">
    <template #title>
      <slot name="title">
        Cathodic Protection Measurement
      </slot>
    </template>

    <template #default v-if="properties">
      <v-card-text>
        <!-- Info Table -->
        <v-simple-table dense class="py-2">
          <template v-slot:default>
            <tbody>
              <template v-for="({ key, value }, i) in infoItems">
                <tr v-if="key && value" :key="`cp-info-item-${i}`">
                  <td><strong><small v-html="key" /></strong></td>
                  <td><small v-text="value" /></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Data Table -->
        <v-simple-table dense class="py-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, i) in dataHeaders"
                  class="text-left"
                  :key="`cp-data-header-${i}`"
                  v-text="header"
                />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="({ date, voff, von }, i) in dataItems"
                :key="`cp-data-item-${i}`"
              >
                <td><small v-text="date" /></td>
                <td><small v-text="voff" /></td>
                <td><small v-text="von" /></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </template>
  </pibot-mapbox-general-popup>
</template>

<script>
/**
 * Assumes that properties are provided in { 'Von/Voff DATE': 0 } format
 */

export default {
  name: 'pibot-mapbox-cp-popup',
  inheritAttrs: false,
  components: {
    'pibot-mapbox-general-popup': () => import('./GeneralPopup')
  },
  props: {
    lngLat: {
      type: Array
    },
    bbox: {
      type: Array
    }
  },
  data: () => ({
    dataHeaders: ['Date', 'V off', 'V on']
  }),
  computed: {
    feature () {
      return this.$options.propsData.feature
    },
    properties () {
      return this.feature.properties
    },
    coordinates () {
      return this.feature.geometry.coordinates.map(g => Number(g.toFixed(5)))
    },
    infoItems () {
      return [
        {
          key: 'Location',
          value: this.properties.Location
        },
        {
          key: 'n&#176; PP',
          value: this.properties.nPP
        },
        {
          key: 'Lat',
          value: this.coordinates[1]
        },
        {
          key: 'Lng',
          value: this.coordinates[0]
        }
      ]
    },
    dataEntries () {
      // Get only the properties with 'key' containing 'Von' or 'Voff'
      const entries = Object.entries(this.properties)
      const filtered = entries.filter(e => e[0].includes('Von') || e[0].includes('Voff'))
      return Object.fromEntries(filtered)
    },
    dataItems () {
      // Get non-duplicate 'dates' from properties' keys - e.g. 'Von 09/2016'
      const dates = [...new Set(Object.keys(this.dataEntries).map(k => k.split(' ')[1]))]

      // Map items
      const items = dates.map(date => ({
        date,
        voff: this.dataEntries[`Voff ${date}`],
        von: this.dataEntries[`Von ${date}`]
      }))

      return items
    }
  }
}
</script>
