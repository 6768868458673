<template>
  <pibot-mapbox-general-popup :lngLat="lngLat"
                              :bbox="bbox"
                              :incident="showLinkToDetails"
                              :incident_id="incident_id">
    <template #title>
      <slot name="title">
          {{ computedTitle }}
      </slot>
    </template>

    <template #default
              v-if="feature.properties">
      <v-simple-table dense
                      class="pb-2">
        <tbody>
          <tr v-for="item in displayItems"
              :key="item.property">
            <td><small>{{ item.property }}</small></td>
            <td><small>{{ item.value | formatNumber }}</small></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </pibot-mapbox-general-popup>
</template>

<script>
import _ from 'lodash'
import tooltipMetaMixin from '@/utils/mixins/meta/tooltipMeta.mixin'
import GeneralPopup from './GeneralPopup'

export default {
  name: 'pibot-mapbox-datatable-popup',
  mixins: [tooltipMetaMixin],
  components: {
    'pibot-mapbox-general-popup': GeneralPopup
  },
  props: {
    feature: {
      type: Object,
      required: true
    },
    hideProperties: {
      type: Array
    },
    lngLat: {
      type: Array
    },
    bbox: {
      type: Array
    },
    showProperties: {
      type: Array
    }
  },
  data () {
    return {
      labels: {
        incident_name: ['Incident name', 0],
        date_of_occureance: ['Date of occurance', 1],
        severity: ['Severity', 2],
        absolute_distance: ['Absolute distance [m]', 3],
        nearby_events: ['# Nearby events', 4],
        assessments: ['Assessment types', 5],
        shape: ['Shape', 6],
        delta: ['Dent size [mm]', 7],
        delta_ratio: ['Dent size [%]', 8],
        P_sop: ['Safe operating pressure [barg]', 9],
        N_hat: ['Lower bound cycles until failure', 10]
      }
    }
  },
  mounted () {
    if (this.showProperties && this.hideProperties) {
      console.warn('Using both showProperties and hideProperties is not supported. Ignoring hideProperties.')
    }
  },
  computed: {
    computedTitle () {
      let metaTitle = 'Incident'
      if (this.layerMeta[this.feature.layer.id]) metaTitle = this.layerMeta[this.feature.layer.id].title

      return metaTitle
    },
    displayItems () {
      const items = []
      if (!this.feature.properties) return items

      for (const property of Object.keys(this.feature.properties)) {
        if (!Object.keys(this.labels).includes(property)) continue
        if (property === 'incident_id') continue

        items.push({
          property: this.labels[property][0],
          value: property === 'delta_ratio' ? this.feature.properties[property] * 100 : property === 'delta' ? this.feature.properties[property] * 1000 : this.feature.properties[property],
          order: this.labels[property][1]
        })
      }

      return _.sortBy(items, ['order'])
    },
    incident_id () {
      return this.feature?.properties?.incident_id
    },
    showLinkToDetails () {
      return !!this.feature?.properties?.assessments?.length
    }
  },
  filters: {
    formatNumber (value) {
      if (isNaN(value)) return value // Not a number
      if (Math.floor(value) === Number(value)) return value // No decimals

      if (value > 100000) return (Math.round(value / 100000) * 100000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      const numDec = value.toString().split('.')[1].length || 0 // Count number of decimals

      if (numDec > 3) return value.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Format to 3 decimals if there are more than 3
      return value // Just return the original value
    }
  }
}
</script>

<style>
.mapboxgl-popup {
  max-width: 25rem !important;
}

.mapboxgl-popup-content {
  padding: 0px;
}
</style>
